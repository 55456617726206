/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/style.scss';

// this "modifies" the jquery module: adding behavior to it
const $ = require('jquery');
// create global $ and jQuery variables
global.$ = global.jQuery = $;

// the bootstrap module doesn't export/return anything
require('bootstrap');

// Local Imports
import './scripts/base.js';